import Cookies from 'js-cookie';
const passObj: { [name: string]: string } = { "3": "xuqvLp4thdqjkSa9fPRjwzLehG4UZUGh","33": "xuqvLp4tdsdfeSa9fPRjwzLehG4UZUGh", "2": "hyYjKMqE6D6u3auBEyFwsB9E4jZSvbuh" }
export default class BasicUtil {
  
  public static getUrlParams() {
    const urls = location.pathname.split("?")
    if (urls.length == 0) return []
    const url = urls[0]
    const arr = url.split("/")
    let first = ""
    let second = ""
    let third = ""
    let forth = ""
    if (arr.length > 1) first = arr[1]
    if (arr.length > 2) second = arr[2]
    if (arr.length > 3) third = arr[3]
    if (arr.length > 4) forth = arr[4]
    return [first, second, third, forth]
  }

  public static getQueryParams(): { [s: string]: string } {
    var queryString = window.location.search;
    var queryObject: { [s: string]: string } = {};
    if (queryString) {
      queryString = queryString.substring(1);
      var parameters = queryString.split('&');

      for (var i = 0; i < parameters.length; i++) {
        var element = parameters[i].split('=');

        var paramName = decodeURIComponent(element[0]);
        var paramValue = decodeURIComponent(element[1]);

        queryObject[paramName] = paramValue;
      }
    }
    return queryObject;
  }

  

  public static isAllow() {
    try {
      localStorage.hoge = 'hoge';
      return true
    } catch (e) {
      return false
    }
  }

  public static getSc() {
    var CryptoJS = require('crypto-js');
    var key = CryptoJS.enc.Utf8.parse('EDbgnrs7Skn9MAsGjB9AnrwjENNbYyvi');
    var iv = CryptoJS.enc.Utf8.parse('8137249822330635');
    var dateTime = new Date().toUTCString()
    var srcs = CryptoJS.enc.Utf8.parse(dateTime);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.ciphertext.toString();
  }

  public setBasicSetting(shikenId: number) {
    window.addEventListener("popstate", (event: PopStateEvent): void => {
      this.reload(shikenId)
    });
  }

  reload (shikenId: number) {
    if (confirm("本当に試験を終了しますか")) {
      this.backtoTop(shikenId)
      }
  }

  backtoTop(shikenId: number) {
    if (shikenId == 1) return location.href = "/"
    location.href = "/text"
  }


  public removeBasicSetting(shikenId: number) {
    window.onbeforeunload = null
    window.removeEventListener("popstate", (event: PopStateEvent): void => {
      this.reload(shikenId)
    });
  }

  public static onlyNumber(val: string): string {
    if (val.length == 0 || val == "") return "";
    val = val.replace(/,/g, '');
    val = val.replace(/０/g, '0');
    val = val.replace(/１/g, '1');
    val = val.replace(/２/g, '2');
    val = val.replace(/３/g, '3');
    val = val.replace(/４/g, '4');
    val = val.replace(/５/g, '5');
    val = val.replace(/６/g, '6');
    val = val.replace(/７/g, '7');
    val = val.replace(/８/g, '8');
    val = val.replace(/９/g, '9');
    if (/[^\-\.0-9]+/.test(val)) return "";
    return Number(val).toLocaleString()
  }

  public static zentoHanNumber(val: string): string {
    if (val.length == 0 || val == "") return "";
    val = val.replace(/０/g, '0');
    val = val.replace(/１/g, '1');
    val = val.replace(/２/g, '2');
    val = val.replace(/３/g, '3');
    val = val.replace(/４/g, '4');
    val = val.replace(/５/g, '5');
    val = val.replace(/６/g, '6');
    val = val.replace(/７/g, '7');
    val = val.replace(/８/g, '8');
    val = val.replace(/９/g, '9');
    if (/[^\-\.0-9]+/.test(val)) return "";
    return val
  }

  public static justifyText(text: string) {
    if (text.indexOf('<span>') !== -1) {
      return text
    }
    const letters: string[] = []
    let isB: boolean = false
    text.split('').forEach((t: string) => {
      if (t == ")") isB = false
      if (isB) {
        letters.push("<span><b>" + t + "</b></span>")
      } else {
        letters.push("<span>" + t + "</span>")
      }
      if (t == "(") isB = true
    })
    return letters.join("")
  }

  public static arrayShuffle(array: any) {
    for (var i = (array.length - 1); 0 < i; i--) {

      var r = Math.floor(Math.random() * (i + 1));

      var tmp = array[i];
      array[i] = array[r];
      array[r] = tmp;
    }
    return array;
  }

  public static setGradePass(grade: string): void {
    Cookies.set('wacbt-' + grade, passObj[grade], { expires: 365 });
  }

  public static isAuth(grade: string): boolean {
    const cookie = Cookies.get('wacbt-' + grade)
    return cookie !== undefined && cookie == passObj[grade]
  }

  public static isAuth3(): boolean {
    return BasicUtil.isAuth("3") || BasicUtil.isAuth("33")
  }

  public static getQa3() {
    const qas = [
      "1200",
    ]
    return qas;
  }

  public static getQa33() {
    const qas = [
      "420",
    ]
    return qas;
  }

  public static getQa2() {
    return []
  }

  
}
