<template>
  <div class="footer">
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";


@Component
export default class Header extends Vue {

  created() {

  }
  mounted() {}
}
</script>
<style scoped>
.footer {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
  left:0;
  padding: 0 !important;
  background: -moz-linear-gradient(top, #5e88ae, #7cb0d8);
  background: -webkit-linear-gradient(top, #5e88ae, #7cb0d8);
  background: linear-gradient(to bottom, #5e88ae, #7cb0d8);
  width: 100%;
}
</style>