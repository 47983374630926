import Vue from 'vue'
import Vuex from 'vuex'
import Question from '../models/question'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    data: [] as Question[],
    dataBack: [] as Question[],
    index: 0 as number,
    goal: 0 as number,
    isRe: false as boolean
  },

  mutations: {
    setQuestions(state, questions: Question[]) {
      state.data = questions
    },
    setQuestionBacks(state, questions: Question[]) {
      state.dataBack = questions
    },
    setGoal(state, goal: number) {
      state.goal = goal
    },
    setIndex(state, i: number) {
      state.index = i
    },
    setIsRe(state, flg: boolean) {
      state.isRe = flg
    },
  }

})

export default store